<template>
    <div v-if="show" @click="hideGuide" class="vertical-scroll-guide">
        <div class="scroll-up-guide">
            <img src="https://6gamehub.com/static/img/novel/assets/scroll-up.webp" alt="show up">
            <p class="scroll-guide-text">上滑翻頁</p>
        </div>

        <div class="show-menu-guide">
            <img src="https://6gamehub.com/static/img/novel/assets/show-menu.webp" alt="show menu">
            <p class="scroll-guide-text">點選頁面 喚起選單</p>
        </div>

        <div class="scroll-down-guide">
            <p class="scroll-guide-text">下滑翻頁</p>
            <img src="https://6gamehub.com/static/img/novel/assets/scroll-down.webp" alt="show down">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: !localStorage.getItem('dh-showed-guide')
        }
    },

    methods: {
        hideGuide() {
            this.show = false
        }
    },

    mounted() {
        // 当前用户是否已经展示过阅读引导组件
        const hasShowGuide = localStorage.getItem('dh-showed-guide')

        // 每个用户只展示一次
        if (!hasShowGuide) {
            localStorage.setItem('dh-showed-guide', true)
        }

        setTimeout(() => {
            this.show = false
        }, 8000);
    }
}
</script>

<style>
.vertical-scroll-guide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.scroll-up-guide {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.scroll-up-guide img {
    width: 150px;
    height: 280px;
}

.show-menu-guide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-align: center;
}

.show-menu-guide img {
    width: 220px;
    height: 320px;
}

.scroll-down-guide {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.scroll-down-guide img {
    width: 150px;
    height: 280px;
}

.scroll-guide-text {
    font-weight: 400;
    font-size: 44px;
    color: #FFFFFF;
    line-height: 44px;
}
</style>