<template>
    <div v-if="isShowGuide" class="change-chapter-guide">
       <div class="chg-dialog">
        <p class="chg-dialog-title">{{dialogTitle}}</p>

        <div class="chg-btn-list">
            <div class="chg-cancel-btn" @click="cancel">
                取消
            </div>

            <!-- 如果有穿插则展示a标签 -->
            <a v-if="isVignetteEnabled" :href="novelLink" class="chg-jump-btn" @click="manualJump">
                {{dialogConfirmText}}（{{countdown}}s）
            </a>

            <div v-else class="chg-jump-btn" @click="manualJump">
                {{dialogConfirmText}}（{{countdown}}s）
            </div>
        </div>
       </div>
    </div>
</template>

<script>
// 倒计时定时器
let countDownTimer = null
// 广告轮训定时器
let adLoopTimer = null

export default {
    data() {
        return {
            innerShow: false,
            countdown: 3,
            // 页面是否有穿插广告，如果有则展示a标签
            isVignetteEnabled: false,
            // 页面是否有插屏广告，如果没有穿插则展示插屏
            isIntersectionEnabled: false
        }
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        onCancel: {
            type: Function,
            default: () => {}
        }
    },

    computed: {
        isShowGuide() {
            return this.innerShow
        },

        novelTitle() {
            const {
                novelTitle
            } = this.$store.state.allChapterData;

            return novelTitle
        },

        novelId() {
            const {
                novelId
            } = this.$store.state.allChapterData;

            return Number(novelId)
        },

        chapterId() {
            // 当前章节id
            const {
                chapterId,
            } = this.$store.state.chapterInfo;

            return Number(chapterId)
        },

        // 当前是不是最后一章
        isLastChapter() {
            const {
                endChapterId,
            } = this.$store.state.allChapterData;

            // 当前章节id
            const {
                chapterId,
            } = this.$store.state.chapterInfo;

            return chapterId == endChapterId
        },

        dialogTitle() {
            return this.isLastChapter ? '最後一章閱讀完成，即將跳轉進入第一章' : '目前章節已閱讀完成，即將跳轉進入下一章'
        },

        dialogConfirmText() {
            return this.isLastChapter ? '進入第一章' : '進入下章'
        },

        novelLink() {
            const chapterId = this.isLastChapter ? 1 : this.chapterId + 1
            return `/${this.novelId}/${chapterId}`
        }
    },

    methods: {
        // 获取穿插/插屏广告dom，判断是否存在
        getVignetteIntersectionAdDom (){
            // 轮训获取ins节点，判断穿插广告是否填充
            const vignetteIns = document.querySelector('ins[data-vignette-loaded="true"]');
            const vignetteFilled = vignetteIns?.getAttribute('data-ad-status') == 'filled';
            // 穿插广告填充
            if (vignetteIns && vignetteFilled) {
                this.isVignetteEnabled = true
                // 如果已经有了穿插则不需要继续轮训
                clearInterval(adLoopTimer);
                return
            }

            // 轮训获取ins节点，判断是否填充
            const interstitialIns = document.querySelector('ins[data-slotcar-interstitial="true"]');
            // data-slotcar-interstitial
            const interstitialFilled = interstitialIns?.getAttribute('data-ad-status') == 'filled';
            // 插屏广告填充
            if (interstitialIns && interstitialFilled) {
                this.isIntersectionEnabled = true
            }
        },

        cancel() {
            // 点击取消需要停止定时器，并重置倒计时时长
            countDownTimer && clearInterval(countDownTimer)
            this.countdown = 3
            this.onCancel?.()
            this.innerShow = false
        },

        // 倒计时结束自动跳转
        autoJump() {
            gtag("click_button", {
                tab: "content_page",
                novelTitle: this.novelTitle,
                novelId: this.novelId,
                chapterId: this.chapterId,
                autoJump: true,
            });
            window.open(this.novelLink, '_self')
        },

        // 倒计时结束前手动跳转，只有手动跳转需要展示插屏，自动跳转不能展示
        manualJump() {
            // 如果已经执行自动跳转逻辑则不可以执行手动跳转逻辑
            if (this.countdown !== 0) {
                countDownTimer && clearInterval(countDownTimer)
                gtag("click_button", {
                    tab: "content_page",
                    novelTitle: this.novelTitle,
                    novelId: this.novelId,
                    chapterId: this.chapterId,
                    manualJump: true,
                });

                if (this.isVignetteEnabled) {
                    // 如果有穿插不执行一下逻辑
                    return
                }

                const novelLink = this.novelLink

                if (this.isIntersectionEnabled) {
                    // 有插屏广告
                    adBreak({
                        type: 'start',
                        name: 'start',
                        adBreakDone: (placementInfo) => {
                            const breakStatus = placementInfo.breakStatus;
                            console.log(
                                '[TecnoAds.showInterstitial] adBreakDone ... breakStatus=' +
                                breakStatus,
                            );

                            setTimeout(() => {
                                window.open(novelLink, '_self')
                            }, 500);
                        },
                    });
                } else {
                    window.open(novelLink, '_self')
                }
            }
        }
    },

    mounted() {
        adLoopTimer = setInterval(() => {
            this.getVignetteIntersectionAdDom();
        }, 50);

        // 5秒后取消广告定时器轮训
        setTimeout(() => {
            clearInterval(adLoopTimer);
        }, 5000);
    },

    watch: {
       show(status) {
        this.innerShow = status

        if (status) {
            countDownTimer = setInterval(() => {
                this.countdown--

                if (this.countdown === 0) {
                    // 倒计时结束，自动跳转，不触发穿插和插屏
                    countDownTimer && clearInterval(countDownTimer)
                    this.autoJump()
                }
            }, 1000)
        }
       }
    }
}
</script>

<style>
.change-chapter-guide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}

.chg-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 80%;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 12px 33px 0px rgba(0,0,0,0.1);
    border-radius: 27px;
}

.chg-dialog-title {
    padding: 50px 140px 10px;
    font-weight: 400;
    font-size: 48px;
    color: #000000;
    line-height: 78px;
}

.chg-btn-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 40px 32px 104px;
}

.chg-cancel-btn {
    margin-right: 80px;
    font-weight: 400;
    font-size: 48px;
    color: #aaa;
    line-height: 78px;
}

.chg-jump-btn {
    flex: 1;
    padding: 18px 0;
    font-size: 48px;
    color: #fff;
    line-height: 78px;
    font-weight: 400;
    background: #008AFF;
    border-radius: 27px;
    text-decoration: none;
}

</style>