<template>
    <!-- 首页-书架页 -->
    <div class="book-store">
        <div class="home-header">
            <div class="app-info">
                <div class="logo-wrap">
                    <img src="https://6gamehub.com/static/img/novel/assets/logo.webp" alt="logo">
                </div>
                <div>
                    <p class="app-name">灯火阅读</p>
                    <p class="app-subtitle">灯火阑珊处,发现美好</p>
                </div>
            </div>
            <a target="_self" href="/history" class="history-link" @click="goHistoryPage">
                閱讀記錄
            </a>
        </div>

        <div class="home-blank-div"></div>

        <div class="book-store-module" v-for="(module, moduleIndex) in storeData" :key="module.title">
            <h2 :class="['book-store-module-title', `book-store-module-title-${moduleIndex + 1}`]">{{ module.title }}
            </h2>
            <div class="book-store-book-list">
                <book-card v-for="book in module.list" :key="book.novelId" :data="book"
                    :direction="moduleIndex === 0 ? 'vertical' : 'horizontal'"
                    :class="{ 'book-store-book-item': moduleIndex === 0, 'book-store-book-item-horizontal': moduleIndex !== 0 }" />
            </div>
        </div>

        <div class="home-blank-div"></div>
    </div>
</template>

<script>
import BookCard from '../components/book-card'
import Loading from "@/components/common/loading";
import http from "@/utils/request";

export default {
    data: () => ({
        // 书架图书
        storeData: []
    }),
    components: {
        BookCard,
    },

    methods: {
        // 请求书架数据
        async getBookStoreData() {
            Loading.show()
            try {
                const res = await http.get(`/novel/book-store.json`)
                this.storeData = (res?.data || []).map(({ title, list = [] }) => ({
                    title,
                    list: list.map(({ novelId, novelTitle, id, title }) => ({
                        novelId: novelId || id,
                        novelTitle: novelTitle || title,
                        coverImg: `https://6gamehub.com/static/img/novel/cover/${novelId || id}.webp`
                    }))
                }))
            } catch (e) { }
            Loading.close()
        },

        goHistoryPage() {
            gtag('click_button', {
                tab: 'home_page',
                history: true
            })
        }
    },

    mounted() {
        gtag('imp_page', {
            page: 'home_page'
        })

        this.getBookStoreData()
    }
};
</script>

<style>
/* ==首页header== */
.home-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 40px;
}

.app-info {
    display: flex;
    align-items: center;
}

.logo-wrap {
    margin-right: 24px;
    font-size: 0;
}

.logo-wrap img {
    width: 111px;
    height: 111px;
}

.app-name {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 48px;
    color: #000000;
    line-height: 54px;
}

.app-subtitle {
    font-weight: 400;
    font-size: 36px;
    color: #979797;
    line-height: 36px;
}

.history-link {
    font-weight: 500;
    font-size: 36px;
    color: #8069F6;
    text-decoration: none;
}

/* ==书架模块== */

.book-store-module {
    margin: 60px 30px;
    padding: 42px 40px 0;
    background-color: #fff;
    border-radius: 36px;
}

/* 空白div，顶起高度 */
.home-blank-div {
    height: 191px;
}

.book-store-module-title {
    position: relative;
    z-index: 1;
    margin: 0 0 36px;
    font-weight: 600;
    font-size: 54px;
    color: #000000;
    line-height: 54px;
}

.book-store-module-title::before {
    position: absolute;
    top: 24px;
    z-index: -1;
    content: '';
    width: 147px;
    height: 42px;
}

.book-store-module-title-1::before {
    background: url('https://6gamehub.com/static/img/novel/assets/header-icon-1.webp') no-repeat;
    background-size: contain;
}

.book-store-module-title-2::before {
    background: url('https://6gamehub.com/static/img/novel/assets/header-icon-2.webp') no-repeat;
    background-size: contain;
}

.book-store-module-title-3::before {
    background: url('https://6gamehub.com/static/img/novel/assets/header-icon-3.webp') no-repeat;
    background-size: contain;
}

.book-store-book-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.book-store-book-item {
    width: 210px;
    margin-bottom: 42px;
}

.book-store-book-item-horizontal {
    flex-basis: 50%;
    margin-bottom: 42px;
}
</style>