import Toast from "@/components/common/toast";

/**
 * 封装关于章节操作的方法
 */

/**
 * 去下一章
 * @param {*} context 上下文
 * @returns
 */
export function goNextChapter(context) {
	const {
		endChapterId,
		novelId,
		novelTitle,
	} = context.$store.state.allChapterData;
	// 当前章节id
	const { chapterId } = context.$store.state.chapterInfo;

	gtag("click_button", {
		tab: "content_page",
		novelTitle,
		novelId,
		chapterId,
		next_chapter: true,
	});

	if (chapterId == endChapterId) {
		return lastChapterToast();
	}

	context.$store.dispatch("getChapterInfo", {
		novelId,
		chapterId: Number(chapterId) + 1,
	});
}

/**
 * 去上一章
 * @param {*} context 上下文
 * @param {Boolean} backEndPage 是否回到章节的最后一页
 * @returns
 */
export function goPreviousChapter(context, backEndPage = true) {
	const {
		startChapterId,
		novelId,
		novelTitle,
	} = context.$store.state.allChapterData;
	// 当前章节id
	const { chapterId } = context.$store.state.chapterInfo;

	gtag("click_button", {
		tab: "content_page",
		novelTitle,
		novelId,
		chapterId,
		previous_chapter: true,
	});

	if (chapterId == startChapterId) {
		return firstChapterToast();
	}

	context.$store.dispatch("getChapterInfo", {
		novelId,
		chapterId: Number(chapterId) - 1,
		backEndPage,
	});
}

/**
 * 更新滑块的位置
 */
export function updateSlideBlockPos(params) {
	const { commit, chapterId, startChapterId, endChapterId } = params;
	commit("updateChapterSliderInfo", {
		progress:
			(chapterId - startChapterId) / (endChapterId - startChapterId + 1),
	});
}

/**
 * 将章节数据存到缓存中
 * @param {*} chapterId 要存储章节的id
 * @param {*} data
 */
export function saveCacheData(params) {
	const { commit, chapterId, data } = params;

	const payload = {};
	payload[chapterId] = data;
	commit("updateCacheChapterInfo", payload);
}

/**
 * 将当前小说的章节数据存储到本地存储
 * @param {Object} params
 */
export function storeChapterInfo(params) {
	const { novelId, chapterId, pageNum } = params;

	// 将章节信息存储到本地，以小说id为key存储
	const novelHistory = JSON.parse(
		localStorage.getItem("novelHistory") || "{}"
	);
	novelHistory[novelId] = {
		chapterId,
		pageNum,
	};
	localStorage.setItem("novelHistory", JSON.stringify(novelHistory));
}

/**
 * 滑动到最后一章的最后一页提示
 */
export function lastChapterToast() {
	return Toast("当前是最后一章");
}

/**
 * 滑动到第一章第一页提示
 */
export function firstChapterToast() {
	return Toast("当前是第一章");
}

// 获取灯火阅读记录
export function getHistoryRecord() {
	return JSON.parse(localStorage.getItem("dh-novel-record") || "[]");
}

// 获取某一本书的浏览记录
export function getBookRecord(id) {
	const recordList = getHistoryRecord();

	return recordList?.find((item) => item.novelId === id) || {};
}

// 将一本小说添加/更新到阅读记录中
export function saveHistoryRecord(data) {
	// 先获取到当前本地存储的老记录
	const recordList = getHistoryRecord();

	// 在老记录中找当前文章，能找到则更新，找不到则需要添加
	const isExist = recordList.some((item) => item.novelId === data.novelId);

	if (isExist) {
		// 更新
		const index = recordList.findIndex(
			(item) => item.novelId === data.novelId
		);
		const oldData = recordList[index];
		recordList.splice(index, 1);
		const newData = {
			...oldData,
			...data,
		};
		newData.coverImg = oldData?.coverImg || data.coverImg;
		recordList.unshift(newData);
	} else {
		// 添加
		recordList.unshift(data);
	}

	// 存储本地
	localStorage.setItem("dh-novel-record", JSON.stringify(recordList));
}
