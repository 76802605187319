<template>
  <pull-refresh :onBottom="showToolMenu" :onRefresh="loadPreviousChapter" :bottomDistance="bottomDistance"
    :canRefreshing="canRefreshing" :flagId="'chapter-pullRefresh'">
    <!-- 垂直滑动翻页 -->
    <div class="reader-page__viewport-vertical" ref="vertical" @click="onClickScreen" @touchstart="onTouchstart">
      <div class="reader-page__viewport-vertical-chapter-container" ref="chapterContainer">
        <!-- 内容有多个chapter盒子组成 -->
        <div v-for="(chapter, index) in chapterList" :key="index" class="reader-page__viewport-vertical__chapter">
          <h2 :class="chapterTitleClass">
            {{ chapter.chapterTitle }}
          </h2>
          <div :class="textColorClass">
            <p v-for="(text, index) in chapter.contentList" :key="index" class="reader-page__content-list--item">
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <vertical-scroll-guide />

    <change-chapter-guide :show="showChangeChapterGuide" :onCancel="onCancelChapterGuide" />
  </pull-refresh>
</template>

<script>
import { firstLoadChapter, showToolBar } from "./helper";
import Toast from "@/components/common/toast/index";
import PullRefresh from "@/components/common/pullRefresh/index.vue";
import VerticalScrollGuide from "./vertical-scroll-guide.vue";
import ChangeChapterGuide from './change-chapter-guide.vue'

export default {
  data() {
    return {
      shouldScrollLoad: true,
      bottomDistance: 100,
      canRefreshing: true,
      // 是否展示切换章节引导弹窗
      showChangeChapterGuide: false
    };
  },

  components: {
    PullRefresh,
    VerticalScrollGuide,
    ChangeChapterGuide
  },

  mounted() {
    const $vertical = this.$refs.vertical;
    const $chapterContainer = this.$refs.chapterContainer;

    this.$store.commit("updateVerticalContainerInfo", {
      $page: document.getElementById("chapter-pullRefresh"),
      $chapterContainer,
    });

    this.$store.dispatch("getChapterListInfo", {
      onLoad: () => firstLoadChapter(this),
    });

    $vertical.addEventListener("touchstart", this.onTouchstart);
  },

  methods: {
    // 点击屏幕显示、隐藏菜单栏
    onClickScreen() {
      showToolBar.call(this);
    },

    // 加载上一章数据
    loadPreviousChapter() {
      const {
        novelId,
        startChapterId,
        novelTitle
      } = this.$store.state.allChapterData;
      // 当前章节id
      const {
        chapterId,
      } = this.$store.state.chapterInfo;


      this.canRefreshing = false;

      if (chapterId === startChapterId) {
        // 两次的props变化时间差太短，使用异步定时器包裹起来
        setTimeout(() => {
          this.canRefreshing = true;
        }, 100);

        return Toast("当前是第一章");
      }

      gtag("event", "scroll_previous_chapter", {
        novelId,
        novelTitle,
        chapterId,
      });

      this.$store.dispatch("getChapterInfo", {
        novelId,
        chapterId: Number(chapterId) - 1,
        pullLoad: true,
        showLoading: false,
        callback: () => {
          if (chapterId - 1 > startChapterId) {
            this.canRefreshing = true;
          } else {
            this.canRefreshing = false
          }
        },
      });
    },

    // 下拉加载下一章数据
    loadNextChapter() {
      if (this.shouldScrollLoad) {
        const {
          novelId,
          endChapterId,
          novelTitle,
        } = this.$store.state.allChapterData;
        // 当前章节id
        const {
          chapterId,
        } = this.$store.state.chapterInfo;

        if (chapterId >= endChapterId) {
          return Toast("当前是最后一章");
        }

        gtag("event", "scroll_next_chapter", {
          novelId,
          novelTitle,
          chapterId,
        });

        // 加载完成前不能再次执行
        this.shouldScrollLoad = false;

        this.$store.dispatch("getChapterInfo", {
          novelId,
          chapterId: Number(chapterId) + 1,
          scrollLoad: true,
          callback: () => {
            this.shouldScrollLoad = true;
          },
        });
      }
    },

    // 滚动到底部自动唤起菜单，提示用户切换章节
    showToolMenu() {
      this.showChangeChapterGuide = true
    },

    onTouchstart() {
      // 监听touchstart事件，当滑动的时候，隐藏菜单栏
      const toolBarVisible = this.$store.state.showToolbar;
      toolBarVisible && showToolBar.call(this);
    },

    onCancelChapterGuide() {
      this.showChangeChapterGuide = false
    }
  },

  computed: {
    /* 章节标题class */
    chapterTitleClass() {
      const { isDayMode } = this.$store.state.currentInfo;

      return `reader-page__chapter-title ${!isDayMode ? "reader-page__chapter-title_night" : ""
        }`;
    },

    chapterList() {
      const { chapterList } = this.$store.state.verticalContainerInfo;

      return chapterList;
    },

    /* 文本颜色 */
    textColorClass() {
      const { isDayMode } = this.$store.state.currentInfo;
      return `reader-page__vertical-content-list ${!isDayMode ? "night-mode__text-color" : ""
        }`;
    },
  },
};
</script>

<style>
.reader-page__viewport-vertical {
  /* height: 100%; */
}

.reader-page__vertical-content-list {
  color: #000;
}
</style>
