<template>
  <div class="tool-bar" :style="`display: ${showToolBar ? 'block' : 'none'}`">
    <!-- 顶部工具栏 目前只作为展示，没有点击功能 -->
    <div :class="topBarClass">
      <a target="_self" href="/" :class="backIconClass" @click="goHome"></a>
      <p :class="novelTitleClass">{{ novelTitle }}</p>
    </div>

    <!-- 底部工具栏 -->
    <div :class="bottomBarClass">
      <!-- 每一个按钮所对应的菜单 -->
      <div class="tool-bar__bottom--menu" v-show="showMenu">
        <component :is="activeMenuComp"></component>
      </div>
      <!-- 按钮icon -->
      <div class="tool-bar__bottom--icons">
        <span v-if="isFirstChapter" class="tool-bar__bottom--text" @click="goPrevious">上一章</span>
        <a v-else class="tool-bar__bottom--text" :href="`/${novelId}/${+chapterId - 1}`" target="_self">上一章</a>

        <div v-for="(item, index) in iconList" :key="index" @click="item.handler"
          :class="item.imgSrc ? 'tool-bar__bottom--icons-item' : ''">
          <img :src="iconSrc(item)" :alt="item.alt" class="tool-bar__bottom--icons-img" />
        </div>

        <span v-if="isLastChapter" class="tool-bar__bottom--text" @click="goNext">下一章</span>
        <a v-else class="tool-bar__bottom--text" :href="`/${novelId}/${+chapterId + 1}`" target="_self">下一章</a>
      </div>
    </div>
  </div>
</template>

<script>
import BgColorMenu from "../menu/bgColor.vue";
import SettingMenu from "../menu/setting.vue";
import { goNextChapter, goPreviousChapter } from "@/utils/chapter";

export default {
  data() {
    return {
      // 控制工具栏的显示隐藏
      showToolBar: false,
      iconList: [
        {
          imgSrc: require("./img/day/contents-day.png"),
          activeImgSrc: require("./img/day/contents-day_active.png"),
          nightImgSrc: require("./img/night/contents.png"),
          nightActiveImgSrc: require("./img/night/contents.png"),
          handler: this.updateContentsStatus,
          name: "contents",
          alt: "目录icon",
        },
        // {
        //   imgSrc: require("./img/day/chapter-day.png"),
        //   activeImgSrc: require("./img/day/chapter-day_active.png"),
        //   nightImgSrc: require("./img/night/chapter-night.png"),
        //   nightActiveImgSrc: require("./img/night/chapter-night_active.png"),
        //   handler: this.wakeupMenu.bind(null, ChangeChapter, "chapter"),
        //   name: "chapter",
        //   alt: "切换章节icon",
        // },
        {
          imgSrc: require("./img/day/bright-day.png"),
          activeImgSrc: require("./img/day/bright-day_active.png"),
          nightImgSrc: require("./img/night/bright-night.png"),
          nightActiveImgSrc: require("./img/night/bright-night_active.png"),
          handler: this.wakeupMenu.bind(null, BgColorMenu, "bright"),
          name: "bright",
          alt: "背景色icon",
        },
        {
          imgSrc: require("./img/day/setting-day.png"),
          activeImgSrc: require("./img/day/setting-day_active.png"),
          nightImgSrc: require("./img/night/setting-night.png"),
          nightActiveImgSrc: require("./img/night/setting-night_active.png"),
          handler: this.wakeupMenu.bind(null, SettingMenu, "setting"),
          name: "setting",
          alt: "设置icon",
        },
      ],
      // 当前激活的菜单组件
      activeMenuComp: "",
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    novelTitle() {
      const {
        novelTitle,
      } = this.$store.state.allChapterData;

      return novelTitle
    },

    topBarClass() {
      const { bgColorNum, isDayMode } = this.$store.state.currentInfo;

      return `tool-bar__top toolbar-bg-color__${bgColorNum} ${this.show ? "tool-bar__top--show" : "tool-bar__top--hide"
        } ${!isDayMode ? "toolbar-bg-color__night" : ""}`;
    },
    bottomBarClass() {
      const { bgColorNum, isDayMode } = this.$store.state.currentInfo;

      return `tool-bar__bottom toolbar-bg-color__${bgColorNum} ${this.show ? "tool-bar__bottom--show" : "tool-bar__bottom--hide"
        } ${!isDayMode ? "toolbar-bg-color__night" : ""}`;
    },

    /* toolbar icon要区分夜间、日间模式和选中状态 */
    iconSrc() {
      const { activeMenu } = this.$store.state.currentInfo;
      const { isDayMode } = this.$store.state.currentInfo;

      return function (item) {
        return activeMenu === item.name
          ? isDayMode
            ? item.activeImgSrc
            : item.nightActiveImgSrc
          : isDayMode
            ? item.imgSrc
            : item.nightImgSrc;
      };
    },

    // 返回按钮class
    backIconClass() {
      const { isDayMode } = this.$store.state.currentInfo;

      return 'tool-bar__top--back'
    },

    shelfIconClass() {
      const { isDayMode } = this.$store.state.currentInfo;

      return `tool-bar__top--shelf-icon ${!isDayMode ? "tool-bar__top--shelf-icon-night" : ""
        }`;
    },

    novelTitleClass() {
      const { isDayMode } = this.$store.state.currentInfo;

      return `tool-bar__top--novelTitle-text ${!isDayMode ? "tool-bar__top--novelTitle-text-night" : ""
        }`;
    },

    // 是否显示menu
    showMenu() {
      return this.$store.state.currentInfo.showMenu;
    },

    novelId() {
      const {
        novelId
      } = this.$store.state.allChapterData;

      return novelId
    },

    chapterId() {
      // 当前章节id
      const {
        chapterId,
      } = this.$store.state.chapterInfo;

      return chapterId
    },

    // 当前是不是第一章
    isFirstChapter() {
      const {
        startChapterId,
      } = this.$store.state.allChapterData;

      // 当前章节id
      const {
        chapterId,
      } = this.$store.state.chapterInfo;

      return chapterId == startChapterId
    },

    // 当前是不是最后一章
    isLastChapter() {
      const {
        endChapterId,
      } = this.$store.state.allChapterData;

      // 当前章节id
      const {
        chapterId,
      } = this.$store.state.chapterInfo;

      return chapterId == endChapterId
    }
  },

  methods: {
    // 更新目录popup状态
    updateContentsStatus() {
      this.$store.commit("updateContentsStatus");
    },

    // 唤起菜单
    wakeupMenu(component, type) {
      const { showMenu } = this.$store.state.currentInfo;
      let isShowMenu = false;

      // 判断菜单当前状态
      if (!showMenu) {
        this.activeMenuComp = component;
        isShowMenu = true;
      } else if (showMenu) {
        if (this.activeMenuComp === component) {
          this.activeMenuComp = "";
          isShowMenu = false;
        } else {
          this.activeMenuComp = component;
          isShowMenu = true;
        }
      }

      this.$store.commit("updateCurrentInfo", {
        showMenu: isShowMenu,
        activeMenu: isShowMenu ? type : "",
      });
    },

    // 上一章 切换章节，更新滑块位置
    goPrevious() {
      goPreviousChapter(this);
    },

    // 下一章 切换章节，更新滑块位置
    goNext() {
      goNextChapter(this);
    },

    goHome() {
      const {
        novelTitle,
        novelId
      } = this.$store.state.allChapterData;
      // 当前章节id
      const {
        chapterId,
      } = this.$store.state.chapterInfo;
      gtag('click_button', {
        tab: 'content_page',
        novelTitle,
        novelId,
        chapterId,
        home: true
      })
    }
  },

  watch: {
    show(status) {
      if (status) {
        this.showToolBar = true;
        const {
          novelTitle,
          novelId
        } = this.$store.state.allChapterData;
        // 当前章节id
        const {
          chapterId,
        } = this.$store.state.chapterInfo;
        gtag("event", "imp_feature", {
          tab: 'content_page',
          novelId,
          novelTitle,
          chapterId,
        });
      } else {
        setTimeout(() => {
          this.showToolBar = false;
        }, 300);
      }
    },
  },
};
</script>

<style>
.tool-bar__top {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 70px 75px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.1);
}

.tool-bar__top--back {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  background: url('https://6gamehub.com/static/img/novel/assets/home-icon.webp') no-repeat;
  background-size: contain;
}

.tool-bar__top--back-night {
  background: url("./img/night/back-night.png") no-repeat center/100%;
}

.tool-bar__top--novelTitle-text {
  font-size: 48px;
  line-height: 48px;
  color: #000;
  font-weight: 600;
}

.tool-bar__top--novelTitle-text-night {
  color: #ffffff;
}

.tool-bar__bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.tool-bar__top--show {
  animation: showTopBar 0.3s forwards;
}

.tool-bar__top--hide {
  animation: hideTopBar 0.3s forwards;
}

.tool-bar__bottom--show {
  animation: showBottomBar 0.3s forwards;
}

/* animation-fill-mode设置为forwards表示保留最后一个状态 */
.tool-bar__bottom--hide {
  animation: hideBottomBar 0.3s forwards;
}

.tool-bar__bottom--menu {
  padding: 20px 75px;
}

.tool-bar__bottom--icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 75px;
}

/* 黑夜模式上一章下一章字体颜色 */
.bg-color__night .tool-bar__bottom--text {
  color: #999;
}

.tool-bar__bottom--text {
  color: #555;
  text-decoration: none;
}

.tool-bar__bottom--icons-item {
  width: 98px;
  height: 98px;
  font-size: 0;
}

.tool-bar__bottom--icons-img {
  width: 100%;
  height: 100%;
}

@keyframes showTopBar {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hideTopBar {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes showBottomBar {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hideBottomBar {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}
</style>