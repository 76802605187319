<template>
    <!-- 图书卡片组件 -->
    <a :href="`/${data.novelId}/${lastChapterId || 1}`" target="_self" @click="handleClick"
        :class="{ 'book-card': true, 'book-card-horizontal': direction == 'horizontal' }">
        <div class="book-cover">
            <img :src="data.coverImg || 'https://6gamehub.com/static/img/cccooperate/newsImage/img-error.png'"
                alt="novel cover">
        </div>
        <!-- 历史记录页使用这种布局 -->
        <div :class="{ 'space-around': !!lastChapterId }">
            <p
                :class="{ 'book-name': true, 'ellipsis': direction === 'vertical', 'ellipsis-2': direction === 'horizontal' }">
                {{
                    data.novelTitle }}</p>

            <p v-if="lastChapterId" class="read-progress">上次讀到第{{ lastChapterId }}章</p>
        </div>
    </a>
</template>

<script>
import { saveHistoryRecord } from '../../utils/chapter';

export default {
    props: {
        data: {
            type: Object,
            default: {
                coverImg: '',
                novelTitle: '',
                novelId: ''
            },
        },

        // 上次阅读到第一章
        lastChapterId: {
            type: Number | String,
            default: undefined
        },

        direction: {
            type: String,
            default: 'horizontal'
        }
    },

    computed: {
        novelLink() {
            const { novelId } = this.data
            return `/${novelId}/${this.lastChapterId || 1}`
        }
    },

    methods: {
        handleClick() {
            const { novelId, coverImg, novelTitle } = this.data

            gtag("event", "click_novel", {
                page: this.lastChapterId ? 'history_page' : 'home_page',
                novelId,
                novelTitle
            });

            saveHistoryRecord({
                novelId,
                coverImg,
                novelTitle,
                chapterId: 1
            })
        }
    }
};
</script>

<style>
.book-card {
    overflow: hidden;
    display: flex;
    /* 默认垂直布局 */
    flex-direction: column;
    text-decoration: none;

}

.book-card-horizontal {
    flex-direction: row;
}

.book-card-horizontal .book-cover {
    margin-right: 24px;
    margin-bottom: 0;
}

.book-cover {
    /* 垂直布局使用 */
    margin-bottom: 18px;
    font-size: 0;
}

/* 垂直布局图片大小 */
img {
    width: 210px;
    height: 280px;
    border-radius: 8px;
}

/* 水平布局图片大小 */
.book-card-horizontal img {
    width: 180px;
    height: 240px;
    border-radius: 8px;
}

.book-name {
    max-height: 120px;
    font-weight: 500;
    font-size: 40px;
    color: #090909;
    line-height: 60px;
}

.read-progress {
    margin-top: 30px;
    font-weight: 400;
    font-size: 36px;
    color: #979797;
    line-height: 36px;
}

.space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
</style>